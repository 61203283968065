<script setup>
import MainHeader from '@/components/layout/MainHeader';
import NavigationPanel from '@/components/layout/NavigationPanel';
import MainFooter from '@/components/layout/MainFooter';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getOrgName } from '@/api/orginfo/orginfo';
import storedUser from '@/utils/storedUser';

const headerKey = ref(0);
const { userError, user } = storedUser();
const orgName = ref(null);
const router = useRouter();

getOrgName(user.orgId).then((res) =>
    orgName.value = res.name
);

const headerRerender = () => {
  headerKey.value += 1;
};

onMounted(() => {
  if (userError) {
    router.push('/login');
  } else {
    headerRerender();
  }
})
</script>

<template>
  <div id="sticky-header">
    <main-header
        :user="user"
        :org-name="orgName"
        :key="headerKey"/>
    <navigation-panel/>
  </div>
  <div id="scrollable-content">
    <router-view/>
    <main-footer/>
  </div>
</template>

<style>
#app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

#scrollable-content {
  height: 100%;
  display: flex;
  flex: 1 0 10%;
  flex-direction: column;
  overflow-y: auto;
}

#sticky-header {
  flex: 0 0 auto;
  width: 100%;
}
</style>