<script setup>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";


const router = useRouter();
const route = useRoute();

const navigate = (meta) => router.push({ name: meta.name });

const isMainScreen = computed(() => route.name == 'Home');
const crumbs = computed(() => route.meta.breadcrumbs);
</script>

<template>
  <div class="breadcrumbs">
    <nav 
      class="breadcrumbs__content container d-flex flex-row align-items-center h-100" 
      aria-label="breadcrumb">
      <div v-show="!isMainScreen" 
        class="breadcrumbs__breadcrumb-item align-items-center inactive"
        @click="router.push({name: 'Home'});">
        <img 
          class="icon"
          src="@/assets/img/icons/icon-navigation-main.svg" 
          draggable="false" 
          id="mainIcon" 
          alt="Navigation icon">
      </div>
      <div 
        class="breadcrumbs__breadcrumb-item h-100 d-flex align-items-center" 
        v-for="item in crumbs" 
        :class="{'active': item.active, 'inactive': !item.active}"
        :key="item" @click="navigate(item.to)">
        <span 
          class="normal-font font-16"
          :class="{'text-white': !item.active, 'text-blue': item.active}">
          {{ item.text }}
        </span>
      </div>
    </nav>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/css/colors.scss';
.breadcrumbs {
  height: 43px;
  background: #008BEA;
}

.breadcrumbs__content {
  --bs-breadcrumb-divider: '';
}

.breadcrumbs__breadcrumb {
  margin-bottom: 0;
  color:#FFFFFF;
  :before{
    color:#FFFFFF;
  }
}

.breadcrumbs__breadcrumb-item {
  padding: 0 24px;
}

.active {
  cursor: pointer;
  background-color: white;
}

.inactive {
  cursor: pointer;
  background-color: $primary-color;
}
</style>