<script setup>
import getFooterInfo from '@/api/index.js';
import { version } from '@/utils/version-utils';
import { ref, computed } from 'vue';


const orgInfo = ref(null);
const footerRendered = computed(() => orgInfo.value !== null);

getFooterInfo().then((result) => orgInfo.value = result);
</script>

<template>
  <div v-if="footerRendered" 
    class="footer py-2 mt-auto">
    <div class="container d-flex flex-row items-center">
      <div class="footer__org-info col-auto">
        <div class="footer__logo col-auto d-flex flex-row align-items-center g-0">
          <img 
            alt="main logo" 
            src="../../assets/img/icons/logo.svg" 
            width="31" 
            height="31">
          <span class="ms-2 org-name fw-bold">
            {{ orgInfo.data.name }}
          </span>
        </div>
        <div class="footer__org-address col-12 mt-1 g-0">
          <span class="org-address"> 
            {{ orgInfo.data.address }}
          </span>
        </div>
      </div>
      <div class="footer__contact-info col-auto ms-5 d-flex flex-column 
        align-items-center justify-content-center">
        <div v-if="orgInfo.data.phones"
          class="footer__phones">
            <div class="footer__phone" 
              v-for="phone in orgInfo.data.phones" 
              :key="phone.phoneNumber">
              {{ phone.phoneNumber }}
            </div>
          </div>
          <div v-if="orgInfo.data.email" 
            class="footer__email">
            {{ orgInfo.data.email }}
        </div>
      </div>
      <div class="footer__version d-flex flex-row w-100 align-items-end justify-content-end">
        <span class="site-version ">
          {{ version }}
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  height: 80px;
  background-color: #FFFFFF;
  margin-top: auto;
  z-index: 0;
}

.org-name {
  font-size: 14px;
  line-height: 19px;
}

.org-address {
  font-size: 12px;
  line-height: 16px;
}

.footer__email, .footer__phone {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.24px;
}

.site-version {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #BDBDBD;
}
</style>