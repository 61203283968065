<script setup>
import BottomDialog from '@/components/popup/BottomDialog.vue';
import { 
  ref, 
  onMounted, 
  onUnmounted, 
  computed, 
  defineProps 
} from 'vue';
import { useRouter } from 'vue-router';


const props = defineProps({
  user: Object,
  orgName: String
});

const header = ref(null);
const confirmDialog = ref(null);
const router = useRouter();
const orgNameVisible = ref(true);
const cabinetTextVisible = ref(true);

const roleName = computed(() => 
  (props.user?.role == 0)  ? 'Пользователь' : 'Администратор' 
    ?? 'Неизвестна'
);

function resize () {
  cabinetTextVisible.value = header.value?.clientWidth >= 540;
  orgNameVisible.value = header.value?.clientWidth >= 960
}

function logout() {
  clearStorage();

  router.push('/login');  
}

function clearStorage() {
  localStorage.removeItem('user');
  localStorage.removeItem('user.token');
  localStorage.removeItem('orgName');
}

function showConfirmDialog() {
  confirmDialog.value.show('Вы уверены что хотите выйти из аккаунта?', logout);
}
 
onMounted(() => {
  if (!props.user) {
    router.push('/login');
  } else {
    window.addEventListener('resize', resize);
  }
});

onUnmounted(() => window.removeEventListener('resize', resize));
</script>

<template>
  <div 
    class="header container py-3 d-flex flex-row">
    <BottomDialog
      ref="confirmDialog"></BottomDialog>
    <div 
      class="header__logo col-auto d-flex flex-row"
      type="button"
      @click="router.push('/')">
      <img 
        alt="main logo" 
        src="../../assets/img/icons/logo.svg">
      <div v-if="cabinetTextVisible" 
        class="col-auto d-flex align-items-center ms-4 
          normal-font font-16 text-blue no-margin-vertical">
          Личный кабинет<br>
          организации
      </div>
    </div>
    <div v-if="orgNameVisible" 
      class="header__org-name col-auto align-self-center ms-4 fw-bold">
      {{ props.orgName }}
    </div>
    <div class="header__actions col d-flex flex-row-reverse 
      align-items-center no-horizontal-padding">
      <div class="header__logout col-auto d-flex flex-row">
        <img 
          src="../../assets/img/icons/icon-logout.svg" 
          type="button"
          alt="logout"
          @click="showConfirmDialog">
      </div>
      <div class="header__profile col-auto d-flex flex-row align-items-center 
        tile-main-cabinet padding-12 me-3">
        <img 
          class="header__profile-icon"
          alt="profile-icon" 
          src="../../assets/img/icons/profile-logo.svg"
          id="profile-icon">
        <div class="header__profile-user col-auto d-flex flex-column no-margin-vertical ms-3">
          <div class="header__profile-user-role font-12 text-gray thin-font no-margin-vertical">
            {{ roleName }}
          </div>
          <div class="header__profile-user-fio font-14 text-black normal-font no-margin-vertical">
              {{ props.user?.fio ?? 'Неизвестно' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/css/colors.scss';
.header {
  height: 80px;
}

.header__org-name {
  font-size: 16px;
  line-height: 19px;
  color: #0B0B0B;
}

.header__profile {
  background-color: white;
  border-radius: 4px;
}

#profile-icon {
  width: 28px;
  height: 28px;
}
</style>